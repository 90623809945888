import React, { useState, useEffect } from "react";
import Header from "./Components/Header/Header";
import MainContainer from "./Components/Maincontainer/MainContainer";
import FloatButtonContainer from "./Components/FloatbuttonContainer/FloatbuttonContainer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

const App = () => {

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define a route with an ID parameter */}
          <Route
            path="/:id"
            element={
              <MainContainer/>
            }
          />
          {/* Add a default route to handle cases without an ID */}
          <Route
            path="/"
            element={
              <MainContainer
                
              />
            }
          />
        </Routes>
        
      </div>
    </Router>
  );
};

export default App;
