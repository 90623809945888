import React, { useState } from "react";
import Header from "../Header/Header";
import TipContainer from "../TipContainer/TipContainer";
import "./RoomContainer.css";

const RoomContainer = () => {
  const [roomNumber, setRoomNumber] = useState("");

  const handleInputChange = (event) => {
    setRoomNumber(event.target.value);
  };

  return (
    <>
      <div className="RoomContainer">
        <p weight="bold" mb="4" align="center" className="sc-gTRrcP jdiviF">
          Room
        </p>
        <input
          type="number"
          bordercolor="#939393"
          focuscolor="#237C9D"
          aria-label="Room"
          placeholder="Enter room"
          className="sc-kgHlns eTrcNZ"
          value={roomNumber}
          onChange={handleInputChange}
        />
      </div>
    </>
  );
};

export default RoomContainer;
