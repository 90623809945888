import React from "react";
import "./TipContainer.css";

const TipContainer = ({ setSelectedItem, selectedItem,LinkArray}) => {
  const data = LinkArray;

  const handlePriceClick = (item) => {
    setSelectedItem({ amount: item.amount, link: item.link });
  };


  return (
    <div className="sc-guKdir cABmFL">
      <p weight="bold" mb="4" align="center" className="sc-gTRrcP jdiviF">
        Tip amount
      </p>
      <div className="sc-eKCYZe bLFZKQ">
        {data.map((item, index) => (
          <div
            key={index}
            onClick={() => handlePriceClick(item)}
            className={`sc-dgDNnZ bkVhky ${
              selectedItem.amount === item.amount ? "selected" : ""
            }`} // Highlight selected item
          >
            <p size="18" weight="bold" className="sc-gTRrcP cNyjmp">
              {item.amount}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TipContainer;
