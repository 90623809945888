import "./Header.css";
import Logoco from "../Logo/Logoco";
const Header = () => {
  return (
    <div className="Header">
      <div className="Logoload">
      <Logoco url="https://motelsimage.s3.us-east-2.amazonaws.com/Color+logo+-+no+background.svg" />
      </div>
    </div>
  );
};
export default Header;
