import React from "react";
import "./Logoco.css";

const Logoco = (url) => {
    const image=url.url
    console.log(image.url)
  return (
    <div className="logoc">
           <img src={image} className="imageges" alt="imAGE" />
    </div>
  );
};

export default Logoco;
