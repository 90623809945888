
import "./FeedbackContainer.css";
const FeedbackContainer = () => {
  return (
    <div class="sc-edMWGd kZiKTv">
      <p weight="bold" my="6" align="center" class="sc-gTRrcP gVomYX">
        Feedback for hotel
      </p>
      <textarea
        bordercolor="#939393"
        focuscolor="#237C9D"
        placeholder="Tell us about your stay or leave feedback for the housekeeper"
        class="sc-ipBXCc jHnEBJ"
      ></textarea>
    </div>
  );
};
export default FeedbackContainer;
