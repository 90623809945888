import React from "react";
import { useNavigate } from "react-router-dom";

import "./FloatbuttonContainer.css";

const FloatbuttonContainer = ({ amount, link }) => {
  const navigate = useNavigate();

  return (
      <div className="sc-eDOyfC daBEHU">
        <a href={link} rel="noopener noreferrer">
          <button
            darkcolor="#237C9D"
            textlightcolor="#FFFFFF"
            className="sc-duSHzT sc-jrrXZk bbiTdk bWsdkr"
          >
            Tip ${amount}
          </button>
        </a>
      </div>
  );
};

export default FloatbuttonContainer;
